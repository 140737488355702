import { render, staticRenderFns } from "./ticket2.vue?vue&type=template&id=64dc35f2&scoped=true&"
import script from "./ticket2.vue?vue&type=script&lang=ts&"
export * from "./ticket2.vue?vue&type=script&lang=ts&"
import style0 from "./ticket2.vue?vue&type=style&index=0&id=64dc35f2&lang=less&scoped=true&"
import style1 from "./ticket2.vue?vue&type=style&index=1&id=64dc35f2&lang=less&scoped=true&"
import style2 from "./ticket2.vue?vue&type=style&index=2&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64dc35f2",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SingleUpload: require('/root/workspace/com.jufair.vue_Z6yh/components/SingleUpload/index.vue').default})
